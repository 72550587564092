
import { defineComponent, reactive, ref, computed } from "vue";
import {
  IonPage,
  IonContent,
  IonItem,
  IonText,
  IonRadioGroup,
  IonCheckbox,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  onIonViewWillEnter,
} from "@ionic/vue";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useI18n } from "vue-i18n";
import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";
import { event as gtagEvent } from "vue-gtag";
import Slider from '@vueform/slider';
import '@vueform/slider/themes/default.css';
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import ProductsList from "../components/ProductsList.vue";
import SliderX from "../components/Slider.vue";
import Banner from "../components/Banner.vue";
export default defineComponent({
  name: "SearchPage",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const { getLocalStorageData } = useLocalStorage();
    const { formatNumberLocale, createLoading } =
      useGlobalFunctions();
    const router = useRouter();
    const route = useRoute();
    const storeX = useStore();

    const search = reactive({
      products: [],
      query: "",
      page: 0,
      pages: 1,
      searchAborter: null,
      started: false,
      suggest: [],
      aggregations: [],
      findYotActive: getLocalStorageData("findYotActive"),
    });

    const filters = reactive({
      categories: [],
      price: {
        from: 0,
        to: 0
      }
    });
    const origPriceRange = ref({
      query: "",
      from: 0,
      to: 0
    });

    const opensortby = ref(false);
    const sortBy = ref(null);
    const sortDir = ref(null);

    const filterReady = ref(false);
    const priceSliderDirection = computed(() => {
      if (storeX.state.storeCode == "ar") {
        return "rtl";
      } else {
        return "ltr";
      }
    });

    const mainCategories = computed(() => {
      const categories = [];
      if (storeX.state.mainCategories.length > 0) {
        storeX.state.mainCategories.forEach((category) => {
          categories.push(category.name);
        });
      }
      return categories;
    });

    const availableFilters = computed(() => {
      const availableFilters = {};
      if (search.aggregations.length > 0) {
        let processed = 0;
        search.aggregations.forEach((aggregation) => {
          processed++;
          if (aggregation.code == "min_price") {
            if (!availableFilters['price']) availableFilters['price'] = {};
            availableFilters['price']['min_price'] = aggregation.value;
            filters.price.from = (filters.price.from == 0) ? aggregation.value : filters.price.from;
            origPriceRange.value.from = (origPriceRange.value.from == 0) ? aggregation.value : origPriceRange.value.from;
          } else if (aggregation.code == "max_price") {
            if (!availableFilters['price']) availableFilters['price'] = {};
            availableFilters['price']['max_price'] = aggregation.value;
            filters.price.to = (filters.price.to == 0) ? aggregation.value : filters.price.to;
            origPriceRange.value.to = (origPriceRange.value.to == 0) ? aggregation.value : origPriceRange.value.to;
          } else {
            if (aggregation.type == 'options') {
              if (!availableFilters[aggregation.code]) availableFilters[aggregation.code] = {};
              if (aggregation.code == 'categories') {
                aggregation.options.forEach((option) => {
                  if (mainCategories.value.includes(option.key)) {
                    if (!availableFilters[aggregation.code]['options']) availableFilters[aggregation.code]['options'] = [];
                    availableFilters[aggregation.code]['options'].push(option);
                  }
                });
              } else {
                availableFilters[aggregation.code]['options'] = aggregation.options;
              }
            }
          }
          if (processed == search.aggregations.length) {
            filterReady.value = true;
          }
        });
      }
      return availableFilters
    });

    const priceRangeValue = computed(() => {
      return [filters.price.from, filters.price.to];
    });
    const priceRangeChanged = (value) => {
      filters.price.from = value[0];
      filters.price.to = value[1];
    }

    const searchProducts = async (event?: any, emptyProducts = false, loading = null) => {
      if (emptyProducts) {
        search.products = [];
        search.aggregations = [];
        search.suggest = [];
        search.page = 0;
        search.pages = 1;
      }
      if (origPriceRange.value.query != search.query) {
        origPriceRange.value.query = search.query;
        origPriceRange.value.from = 0;
        origPriceRange.value.to = 0;
      }

      //const loading = await createLoading(t("pleaseWait"));
      search.page = search.page + 1;
      if (search.page > search.pages) {
        if (event) {
          event.target.complete();
        }
        return;
      }
      if (!event) {
        //loading.present();
      }
      let aborted = false;
      if (search.searchAborter) {
        search.searchAborter.abort();
        aborted = true;
      }
      filterReady.value = false;
      if (search.query.length > 0) {
        search.searchAborter = new AbortController();
        const signal = search.searchAborter.signal;
        let url = settingsApp.getEndpointUrl("findyotSearch");
        if (!search.findYotActive) {
          url = settingsApp.getEndpointUrl("catalogSearch");
        }
        search.started = true;
        const sentFilters = {};
        if (filters.categories.length > 0) {
          sentFilters['categories'] = filters.categories;
        }
        if (filters.price.from && filters.price.to) {
          sentFilters['price'] = {
            from: filters.price.from,
            to: filters.price.to
          }
        }
        const sentSort = {};
        if (sortBy.value && sortDir.value) {
          sentSort['field'] = sortBy.value;
          sentSort['dir'] = sortDir.value;
        }
        httpApp
          .sendPostRequest(
            url,
            {
              store_view: getLocalStorageData("storeCode"),
              q: search.query,
              query: search.query,
              limit: 40,
              page: search.page,
              filters: sentFilters,
              sort: sentSort,
            },
            true,
            signal
          )
          .then(async (response: any) => {
            gtagEvent("search", { search_term: search.query });
            if (loading) loading.dismiss();
            if (response.success) {
              let products;
              if (search.findYotActive) {
                products = response.data.products;
                search.aggregations = response.data.aggregations;
              } else {
                products = response.items;
              }
              if (Number(search.page) == Number(search.pages)) {
                if (event) {
                  event.target.complete();
                }
              }
              products.forEach((item: any) => {
                search.products.push(item);
              });
              search.pages = Number(response.pager.pages);
              search.page = Number(response.pager.current_page);
              if (Number(response.pager.pages) == Number(response.pager.current_page)) {
                if (event) {
                  event.target.complete();
                }
              }
              gtagEvent("view_search_results", {
                search_term: search.query,
                items_count: search.products.length,
              });

            } else {
              if (event) {
                event.target.complete();
              } else {
                //loading.dismiss();
              }
              if (Object.prototype.hasOwnProperty.call(response, "suggest")) {
                if (response.suggest.length > 0) {
                  search.suggest = response.suggest;
                }
              }
              if (search.products.length > 0) {
                search.page = 1;
              }
              search.aggregations = [];
            }
            search.started = false;
          })
          .catch((error) => {
            if (loading) loading.dismiss();
            console.log(error, "error");
            if (event) {
              event.target.complete();
            } else {
              //loading.dismiss();
            }
            if (!aborted) {
              search.started = false;
            }
          });
      } else {
        if (event) {
          event.target.complete();
        } else {
          //loading.dismiss();
        }
        search.started = false;
      }
    };

    const setNewSearch = async (text) => {
      search.query = text;
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      searchProducts(null, true, loading);
    };

    const applyFilters = async () => {
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      searchProducts(null, true, loading);
    }

    const filterChanged = (event, filterCode) => {
      const value = event.target.value;
      if (filters[filterCode].includes(value)) {
        filters[filterCode].splice(filters[filterCode].indexOf(value), 1);
      } else {
        filters[filterCode].push(value);
      }
      applyFilters();
    }

    const sortingAction = async (title = null, direction = null) => {
      sortBy.value = title;
      sortDir.value = direction;
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      searchProducts(null, true, loading);
    };

    const setSearchQuery = async (data) => {
      search.query = data.query;
      filters.categories = [];
      filters.price.from = 0;
      filters.price.to = 0;
      sortBy.value = null;
      sortDir.value = null;
      // router.replace({
      //   name: "searchPage",
      //   params: {
      //     query: search.query,
      //   },
      // });
      searchProducts(null, true);
    };

    const getSuggestText = (item) => {
      let text = item.text;
      if (Number(item.freq) > 1) {
        text += " (" + t('searchTermUsed', { freq: item['freq'] }) + ")";
      }
      return text;
    }

    const navToHome = () => {
      router.push("/");
    };

    onIonViewWillEnter(() => {
      search.query = route.params.query as string;
      searchProducts(null, true);
    });

    return {
      t,
      search,
      setSearchQuery,
      getSuggestText,
      setNewSearch,
      applyFilters,
      filterReady,
      priceSliderDirection,
      formatNumberLocale,
      availableFilters,
      filterChanged,
      priceRangeValue,
      priceRangeChanged,
      filters,
      origPriceRange,
      opensortby,
      sortBy,
      sortingAction,
      navToHome,
      storeX,
      searchProducts,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonText,
    IonRadioGroup,
    IonCheckbox,
    Slider,
    MainHeader,
    ProductsList,
    SliderX,
    Banner,
    MainFooter,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
});
